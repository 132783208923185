import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';

export const Navbar = styled.div`
  align-items: center;
  display: flex;
  box-shadow: 0 0 0 transparent;
  border-bottom: 1px solid transparent;
  left: 0;
  padding: 1.6rem 3.2rem;
  position: fixed;
  top: 0;
  transition-duration: 400ms;
  transition-property: background-color, border-bottom-color, box-shadow;
  width: 100%;
  will-change: background-color, border-bottom-color, box-shadow;
  z-index: ${({ theme }) => theme.indexes.navbar};

  &.--docked {
    background-color: ${({ theme }) => theme.colors.lightest};
    border-bottom-color: ${({ theme }) => theme.colors.dark.alpha(0.16)};
    box-shadow: ${({ theme }) => theme.shadows.md};
  }

  &.--active {
    background-color: ${({ theme }) => theme.colors.lightest};
    border-bottom-color: transparent;
    box-shadow: 0 0 0 transparent;
  }

  @media (min-width: 768px) {
    padding: 1.6rem 6.4rem;
  }
  @media (min-width: 1200px) {
    padding: 1.6rem 12.8rem;
  }
  @media (min-width: 1400px) {
    padding: 1.6rem calc((100vw - 1400px) / 2);
  }
`;

export const Brand = styled(GatsbyImage)`
  object-fit: contain;
  object-position: center;
  height: 3.2rem;
  width: auto;
`;

export const NavLinks = styled.div`
  margin: 0 0 3.2rem 0;

  @media (min-width: 1200px) {
    margin: 0;
  }
`;

export const Nav = styled.nav`
  background: ${({ theme }) => theme.colors.lightest};
  bottom: 0;
  height: calc(100% - 80px);
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 3.2rem;
  position: fixed;
  transform: scaleY(0);
  transform-origin: top center;
  transition-duration: 400ms;
  transition-property: transform, opacity, visibility;
  visibility: hidden;
  width: 100%;
  will-change: transform, opacity, visibility;
  z-index: ${({ theme }) => theme.indexes.navbarOverlay};

  &,
  > ${NavLinks} {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.--visible {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }

  @media (min-width: 1200px) {
    background-color: transparent;
    bottom: auto;
    opacity: 1;
    padding: 1rem 0 1rem 3.2rem;
    left: auto;
    transform: scale(1);
    visibility: visible;
    width: 100%;

    &,
    > ${NavLinks} {
      position: relative;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
`;

export const Toggler = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  height: ${({ theme }) => theme.sizes.btn};
  justify-content: center;
  margin: 0 1rem 0 0;
  outline-color: ${({ theme }) => theme.colors.primaryDark.alpha(0.48)};
  transform-origin: center;
  transition: transform 400ms;
  width: ${({ theme }) => theme.sizes.btn};
  will-change: transform;

  :active {
    transform: scale(0.96);
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

export const NavLink = styled.a`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryDark.hex()};
  display: flex;
  font-size: ${({ theme }) => theme.sizes.p};
  line-height: ${({ theme }) => theme.lineHeights.p};
  height: ${({ theme }) => theme.sizes.btn};
  justify-content: center;
  margin: 0.4rem 0;
  text-align: center;
  width: 100%;
  white-space: nowrap;

  @media screen and (max-width: 1199px) {
    :last-of-type {
      margin: 0 0 1rem 0;
    }
  }

  @media screen and (min-width: 1200px) {
    margin: 0;
    padding: 0 2rem;
    width: fit-content;

    :last-of-type {
      margin: 0 1.6rem 0 0;
    }
  }
`;

export const Socials = styled.div`
  align-items: center;
  bottom: 32px;
  display: flex;
  justify-content: center;
  position: absolute;

  @media screen and (min-width: 1200px) {
    bottom: auto;
    position: relative;
    margin: 0 0 0 1.6rem;
  }
`;

export const Social = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.primaryDark.hex()};
  height: ${({ theme }) => theme.sizes.btn};
  padding: 1.2rem;
  transition: color 400ms;
  width: ${({ theme }) => theme.sizes.btn};
  will-change: color;

  :hover {
    color: ${({ theme }) => theme.colors.primary.alpha(0.88)};
  }
`;

export const NavAction = styled.a`
  align-items: center;
  background: ${({ theme }) => theme.gradients.secondary};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  color: ${({ theme }) => theme.colors.lightest.hex()};
  display: flex;
  height: ${({ theme }) => theme.sizes.btn};
  justify-content: center;
  margin: 0 0 0 auto;
  padding: 0 2rem;
  transform-origin: center;
  transition: transform 400ms;
  will-change: transform;

  :active {
    transform: scale(0.96);
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;
