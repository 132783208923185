import styled from 'styled-components';
import { Carousel } from "react-responsive-carousel";
import GatsbyImage from 'gatsby-image';

export const About = styled.section`
  padding: 12.8rem 3.2rem 6.4rem 3.2rem;

  @media screen and (min-width: 768px) {
    padding: 12.8rem 6.4rem 6.4rem 6.4rem;
  }
  @media screen and (min-width: 1200px) {
    padding: 12.8rem 12.8rem 6.4rem 12.8rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 12.8rem calc((100vw - 1400px) / 2) 6.4rem
      calc((100vw - 1400px) / 2);
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.dark.hex()};
  font-size: ${({ theme }) => theme.sizes.h1};
  font-weight: ${({ theme }) => theme.weights.strong};
  line-height: ${({ theme }) => theme.lineHeights.title};
  text-align: center;
  margin: 0 0 4rem 0;
`;

export const ItemsGrid = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 6.4rem;
  }
`;

export const Photo = styled(GatsbyImage)`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: auto;
`;

export const PhotoSlider = styled.ul`
  box-shadow: ${({ theme }) => theme.shadows.md};
  display: flex;
  height: auto;
  justify-self: flex-end;
  max-height: 72rem;
  max-width: 72rem;
  overflow-x: hidden;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const PhotoSlide = styled.li`
  display: flex;
  justify-content: center;
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  counter-increment: item;
  height: auto;
`

export const GridContent = styled.span`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.sizes.p};
  color: ${({ theme }) => theme.colors.dark.hex()};
  line-height: ${({ theme }) => theme.lineHeights.p};
  text-align: justify;
`;

export const ParagraphGrid = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 6.4rem 0 0 0;

  @media screen and (min-width: 1400px) {
    margin: 8rem auto 0 auto;
  }

  > ${Title} {
    margin: 0;
  }

  > ${GridContent} > ${Title} {
    display: none;
  }

  @media screen and (max-width: 1023px) {
    > ${Photo} {
      margin: 3.2rem 0;
    }
  }

  @media screen and (min-width: 1024px) {
    column-gap: 6.4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    > ${Photo} {
      height: auto;
      width: 100%;
    }

    > ${Title} {
      display: none;
    }
    > ${GridContent} > ${Title} {
      display: block;
      margin: 0 0 1.6rem 0;
      text-align: left;
      width: 100%;
    }
  }
`;

export const GalleryCarousel = styled(Carousel)`
    background-color: pink;

  @media screen and (max-width: 1023px) {
    height: 100%;
    margin: 2rem 0;
    width: 80vw;
  }
`

export const VideoWrapper = styled.div`
    overflow: hidden;
    position: relative;
    margin-bottom: 2rem;
    width:100%;

    &::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
`