import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Whatsapp } from 'styled-icons/boxicons-logos';
import { ChevronLeft, Send, Phone } from 'styled-icons/material';

import { $scrollTo } from '../../../utils';
import { Button } from '../../index';

import * as S from './styled';

const Home = () => {
  const data = useStaticQuery(graphql`
    {
      smFile: file(relativePath: { eq: "logo-sm-home-dark.png" }) {
        childImageSharp {
          fixed(width: 272) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lgFile: file(relativePath: { eq: "logo-lg-dark.png" }) {
        childImageSharp {
          fixed(height: 189) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          title,
          description
          homeActions {
            whatsapp
            call
            form
            services
          }
          attendance {
            items {
              title
              display {
                text
                url
              }
            }
          }
        }
      }
    }
  `);

  function actionClickHandler(ref, offset = null) {
    if (ref.includes('http')) return window.open(ref, '_blank');
    if (ref.includes('tel:')) return window.open(ref, '_blank');
    return $scrollTo.go(ref, offset);
  }

  const [{ url: whatsAppLink }] = data.site.siteMetadata.attendance.items.find(
    (each) => each.title === 'WhatsApp',
  ).display;
  const [{ text: phone }] = data.site.siteMetadata.attendance.items.find(
    (each) => each.title === 'Telefones',
  ).display;
  const phoneLink = `tel:${phone}`;
  return (
    <S.Home>
      <S.Title>{data.site.siteMetadata.title}</S.Title>
      <S.Logo
        fixed={[
          data.smFile.childImageSharp.fixed,
          {
            ...data.lgFile.childImageSharp.fixed,
            media: `(min-width: 1024px)`,
          },
        ]}
      />
      <S.Description>
      {data.site.siteMetadata.description}
      </S.Description>
      <S.Actions>
        <Button
          variant="secondary"
          onClick={() => actionClickHandler(whatsAppLink)}
          cta
        >
          <Whatsapp size={24} />
          {data.site.siteMetadata.homeActions.whatsapp}
        </Button>
        <Button onClick={() => actionClickHandler(phoneLink)} cta>
          <Phone size={24} />
          {data.site.siteMetadata.homeActions.call} {phone}
        </Button>
        <Button onClick={() => actionClickHandler('form', 128)} cta>
          <Send size={24} />
          {data.site.siteMetadata.homeActions.form}
        </Button>
        <Button
          variant="transparent"
          onClick={() => actionClickHandler('#services')}
          cta
        >
          <ChevronLeft size={24} style={{ transform: 'rotate(-90deg)' }} />
          {data.site.siteMetadata.homeActions.services}
        </Button>
      </S.Actions>
    </S.Home>
  );
};

export default Home;
