import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Attendance from './Attendance';
import Doctors from './Doctors';
import Others from './Others';
import Signature from './Signature';
import Social from './Social';
import * as S from './styled';

export default function Footer() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          authorDisplay
          footerOtherInfosTitle
          publishYear
          title
          hours {
            title
            display
          }
          consultationsWarning {
            title
            display
          }
          attendance {
            title
            items {
              title
              display {
                text
                url
              }
              obs
            }
          }
          doctors {
            name
            registry
          }
          social {
            title
            items {
              title
              display
              url
            }
          }
        }
      }
      smBrand: file(relativePath: { eq: "logo-sm.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lgBrand: file(relativePath: { eq: "logo-lg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <S.Footer>
      <span>
        <S.Brand
          alt={data.site.siteMetadata.title}
          fluid={[
            data.smBrand.childImageSharp.fluid,
            {
              ...data.lgBrand.childImageSharp.fluid,
              media: '(min-width: 768px)',
            },
          ]}
          title={data.site.siteMetadata.title}
        />
        <S.Extra>Conheça também a <a href='https://www.cnxbpo.com/' rel='noreferrer noopener' target='_blank'>CNX BPO</a></S.Extra>
        {/* <Doctors doctors={data.site.siteMetadata.doctors} /> */}
      </span>
      <Social
        title={data.site.siteMetadata.social.title}
        items={data.site.siteMetadata.social.items}
      />
      <Attendance
        title={data.site.siteMetadata.attendance.title}
        items={data.site.siteMetadata.attendance.items}
      />
      <Others
        title={data.site.siteMetadata.footerOtherInfosTitle}
        items={[
          data.site.siteMetadata.hours,
        ]}
      />
      <Signature
        author={data.site.siteMetadata.authorDisplay}
        publishYear={data.site.siteMetadata.publishYear}
        title={data.site.siteMetadata.title}
      />
    </S.Footer>
  );
}
